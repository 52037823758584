<template>
  <Swiper
    ref="swiperRef"
    class="hm-swiper"
    :slides-per-view="slidesPerView"
    :space-between="spaceBetween"
    :loop="loop"
    :autoplay="autoplay"
    :pagination="paginationOption"
    :navigation="navigation"
    :modules="[Autoplay, Navigation, Pagination]"
    :direction="direction"
    :auto-height="autoHeight"
    :initial-slide="initialSlide"
    :effect="effect"
    :centered-slides="centeredSlides"
    @swiper="onSwiper"
    @slide-change="onSlideChange"
  >
    <!-- note: <swiper-slide>をwrapする -->
    <slot />
  </Swiper>
</template>
<script setup lang="ts">
// @see https://swiperjs.com/vue
import { Swiper, useSwiper as _useSwiper } from 'swiper/vue'
import { Autoplay, Navigation, Pagination } from 'swiper'
import type { Swiper as SwiperClass } from 'swiper/types'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/scss/virtual'
import 'swiper/css/navigation'
// @see node_modules\swiper\vue\swiper-vue.d.ts
// todo: 必要ならpropsを増やす
type Props = {
  slidesPerView?: number
  spaceBetween?: number
  autoplay?: boolean
  pagination?: boolean
  navigation?: boolean
  loop?: boolean
  autoHeight?: boolean
  direction?: 'horizontal' | 'vertical' | undefined
  initialSlide?: number
  centeredSlides?: boolean
}
type Emits = {
  (emit: 'swiper', swiper: SwiperClass): void
  (emit: 'slide-change', swiper: SwiperClass): void
}
const emits = defineEmits<Emits>()
const swiperRef = ref<typeof Swiper | null>(null)
const props = withDefaults(defineProps<Props>(), {
  slidesPerView: 3,
  spaceBetween: 50,
  autoplay: false,
  pagination: false,
  loop: false,
  autoHeight: false,
  coverflowEffect: undefined,
  direction: undefined,
  initialSlide: 0,
  centeredSlides: false,
})
const effect = computed(() => (props.coverflowEffect ? 'coverflow' : undefined))
const paginationOption = computed(() =>
  props.pagination ? { el: '.swiper-pagination', clickable: true } : false
)
const onSwiper = (swiper: SwiperClass) => {
  emits('swiper', swiper)
}
const onSlideChange = (swiper: SwiperClass) => {
  emits('slide-change', swiper)
}
</script>
<style lang="scss">
// note: styleは極力Hoに書いてね
</style>
